import { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { BrandColors } from '../../../../types';
import { createNewHexColorViaHSL, HEXtoRGB } from '../../../styled/helpers';

export type PaymentBrandColors = {
  primary: string;
  primaryRGB: string;
  primaryLight: string;
  primaryLightRGB: string;
  secondary: string;
  secondaryRGB: string;
  secondaryDark: string;
  secondaryDarkRGB: string;
  accent: string;
  accentRGB: string;
};

export function useBrandColors() {
  const {
    brandColors: {
      primary,
      secondary,
      accent,
      primaryRGB,
      secondaryRGB,
      accentRGB,
    },
  } = useContext<{
    brandColors: BrandColors;
  }>(ThemeContext);

  return useMemo(() => {
    const primaryLight = createNewHexColorViaHSL(primary, 5, 92);
    const secondaryDark = createNewHexColorViaHSL(secondary, 50, 12);

    const paymentBrandColors: PaymentBrandColors = {
      primary,
      primaryRGB,
      primaryLight,
      primaryLightRGB: HEXtoRGB(primaryLight),
      secondary,
      secondaryRGB,
      secondaryDark,
      secondaryDarkRGB: HEXtoRGB(secondaryDark),
      accent,
      accentRGB,
    };

    return paymentBrandColors;
  }, [primary, secondary, accent, primaryRGB, secondaryRGB, accentRGB]);
}
