import {
  enGB,
  de,
  fr,
  es,
  pt,
  ptBR,
  ru,
  vi,
  frCA,
  ar,
  zhCN,
} from 'date-fns/locale';
import { Locale } from 'date-fns';
import { SiteLocales } from '../../types';

const localesMatcher: Record<string, Locale> = {
  [SiteLocales.en]: enGB,
  [SiteLocales.de]: de,
  [SiteLocales.fr]: fr,
  [SiteLocales.es]: es,
  [SiteLocales.pt]: pt,
  [SiteLocales.br]: ptBR,
  [SiteLocales.ru]: ru,
  [SiteLocales.vn]: vi,
  [SiteLocales.cf]: frCA,
  [SiteLocales.ar]: ar,
  [SiteLocales.cn]: zhCN,
};

export function getDateFnsLocale(locale: string) {
  return localesMatcher[locale] || enGB;
}
