import { useTheme } from '@mui/material/styles';
import { InputAdornmentProps } from '@mui/material/InputAdornment';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import { MuiInputAdornment } from '../../../../../modules/material';

export interface FieldStateAdornmentProps
  extends Omit<InputAdornmentProps, 'position'> {
  completed?: boolean;
  error?: boolean;
  position?: InputAdornmentProps['position'];
}

export default function FieldStateAdornment(props: FieldStateAdornmentProps) {
  const {
    error = false,
    completed = false,
    position = 'end',
    sx,
    ...adornmentProps
  } = props;

  const theme = useTheme();

  switch (true) {
    case error:
      return (
        <MuiInputAdornment
          position={position}
          {...adornmentProps}
          sx={{ color: theme.palette.customError.mainOnLight, ...sx }}
        >
          <ErrorIcon />
        </MuiInputAdornment>
      );
    case completed:
      return (
        <MuiInputAdornment
          position={position}
          {...adornmentProps}
          sx={{ color: theme.palette.customSuccess.mainCustom, ...sx }}
        >
          <CheckIcon />
        </MuiInputAdornment>
      );
    default:
      return null;
  }
}
