import { ForwardedRef, forwardRef } from 'react';
import { Palette } from '@mui/material/styles';
import {
  TypographyProps as MuiTypographyProps,
  TypographyPropsVariantOverrides,
} from '@mui/material/Typography';
import { StyledTypography } from './styles';
import { PaletteColors } from '../../../types/enums';

export interface TypographyProps extends MuiTypographyProps {
  variant?:
    | MuiTypographyProps['variant']
    | keyof TypographyPropsVariantOverrides;
  opacity?: number;
  neutralColorIndex?: keyof Palette['neutral'];
  paletteColorName?: keyof typeof PaletteColors;
}

const Typography = forwardRef(function Typography(
  props: TypographyProps,
  ref: ForwardedRef<HTMLSpanElement>,
) {
  const {
    variant = 'body1',
    neutralColorIndex = 800,
    children,
    ...typographyProps
  } = props;

  return (
    <StyledTypography
      variant={variant}
      neutralColorIndex={neutralColorIndex}
      {...typographyProps}
      ref={ref}
    >
      {children}
    </StyledTypography>
  );
});

export default Typography;
