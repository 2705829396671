import { createTheme } from '@mui/material/styles';
import { palette } from './palette';
import { components } from './components';
import { typography } from './typography';

// Generate base theme object for breakpoints availability
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1440,
    },
  },
  palette,
  components,
  typography,
});

export default theme;
