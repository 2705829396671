export const typography = {
  h1: {
    fontFamily: 'var(--payment-typography-h1-font-family)',
    fontWeight: 'var(--payment-typography-h1-font-weight)',
    fontSize: 'var(--payment-typography-h1-font-size)',
    lineHeight: 'var(--payment-typography-h1-line-height)',
  },
  h2: {
    fontFamily: 'var(--payment-typography-h2-font-family)',
    fontWeight: 'var(--payment-typography-h2-font-weight)',
    fontSize: 'var(--payment-typography-h2-font-size)',
    lineHeight: 'var(--payment-typography-h2-line-height)',
  },
  h3: {
    fontFamily: 'var(--payment-typography-h3-font-family)',
    fontWeight: 'var(--payment-typography-h3-font-weight)',
    fontSize: 'var(--payment-typography-h3-font-size)',
    lineHeight: 'var(--payment-typography-h3-line-height)',
  },
  h4: {
    fontFamily: 'var(--payment-typography-h4-font-family)',
    fontWeight: 'var(--payment-typography-h4-font-weight)',
    fontSize: 'var(--payment-typography-h4-font-size)',
    lineHeight: 'var(--payment-typography-h4-line-height)',
  },
  h5: {
    fontFamily: 'var(--payment-typography-h5-font-family)',
    fontWeight: 'var(--payment-typography-h5-font-weight)',
    fontSize: 'var(--payment-typography-h5-font-size)',
    lineHeight: 'var(--payment-typography-h5-line-height)',
  },
  h6: {
    fontFamily: 'var(--payment-typography-h6-font-family)',
    fontWeight: 'var(--payment-typography-h6-font-weight)',
    fontSize: 'var(--payment-typography-h6-font-size)',
    lineHeight: 'var(--payment-typography-h6-line-height)',
  },
  subtitle1: {
    fontFamily: 'var(--payment-typography-subtitle1-font-family)',
    fontWeight: 'var(--payment-typography-subtitle1-font-weight)',
    fontSize: 'var(--payment-typography-subtitle1-font-size)',
    lineHeight: 'var(--payment-typography-subtitle1-line-height)',
  },
  subtitle2: {
    fontFamily: 'var(--payment-typography-subtitle2-font-family)',
    fontWeight: 'var(--payment-typography-subtitle2-font-weight)',
    fontSize: 'var(--payment-typography-subtitle2-font-size)',
    lineHeight: 'var(--payment-typography-subtitle2-line-height)',
  },
  caption1: {
    fontFamily: 'var(--payment-typography-caption1-font-family)',
    fontWeight: 'var(--payment-typography-caption1-font-weight)',
    fontSize: 'var(--payment-typography-caption1-font-size)',
    lineHeight: 'var(--payment-typography-caption1-line-height)',
  },
  caption2: {
    fontFamily: 'var(--payment-typography-caption2-font-family)',
    fontWeight: 'var(--payment-typography-caption2-font-weight)',
    fontSize: 'var(--payment-typography-caption2-font-size)',
    lineHeight: 'var(--payment-typography-caption2-line-height)',
  },
  superText: {
    fontFamily: 'var(--payment-typography-super-text-font-family)',
    fontWeight: 'var(--payment-typography-super-text-font-weight)',
    fontSize: 'var(--payment-typography-super-text-font-size)',
    lineHeight: 'var(--payment-typography-super-text-line-height)',
  },
  featuredText: {
    fontFamily: 'var(--payment-typography-featured-text-font-family)',
    fontWeight: 'var(--payment-typography-featured-text-font-weight)',
    fontSize: 'var(--payment-typography-featured-text-font-size)',
    lineHeight: 'var(--payment-typography-featured-text-line-height)',
  },
  body1: {
    fontFamily: 'var(--payment-typography-body1-font-family)',
    fontWeight: 'var(--payment-typography-body1-font-weight)',
    fontSize: 'var(--payment-typography-body1-font-size)',
    lineHeight: 'var(--payment-typography-body1-line-height)',
  },
  smallText: {
    fontFamily: 'var(--payment-typography-small-text-font-family)',
    fontWeight: 'var(--payment-typography-small-text-font-weight)',
    fontSize: 'var(--payment-typography-small-text-font-size)',
    lineHeight: 'var(--payment-typography-small-text-line-height)',
  },
  button: {
    fontFamily: 'var(--payment-typography-button-font-family)',
    fontWeight: 'var(--payment-typography-button-font-weight)',
    fontSize: 'var(--payment-typography-button-font-size)',
    lineHeight: 'var(--payment-typography-button-line-height)',
  },
  buttonSmall: {
    fontFamily: 'var(--payment-typography-button-small-font-family)',
    fontWeight: 'var(--payment-typography-button-small-font-weight)',
    fontSize: 'var(--payment-typography-button-small-font-size)',
    lineHeight: 'var(--payment-typography-button-small-line-height)',
  },
};
