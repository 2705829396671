import { ThemeOptions } from '@mui/material';
import { PaletteColors } from '../../../types/enums';

// This mapper links color names used in the design to their corresponding names in the brand admin panel.
// The mapping is as follows:
// "Extra Light" in design corresponds to "Primary Light" in admin.
// "Light" in design corresponds to "Primary" in admin.
// "Main" in design corresponds to "Secondary" in admin.
// "Dark" in design corresponds to "Secondary Dark" in admin.
// "Accent" in design corresponds to "Accent" in admin.

// Approach with variables works only with custom colors
// Usage with default material colors will cause errors in colorsManipulator.js
export const palette: ThemeOptions['palette'] = {
  [PaletteColors.brandPrimary]: {
    lightCustom: 'var(--payment-palette-primary-light)',
    lightRGB: 'var(--payment-palette-primary-light-rgb)',
    mainCustom: 'var(--payment-palette-primary)',
    mainRGB: 'var(--payment-palette-primary-rgb)',
  },
  [PaletteColors.brandSecondary]: {
    mainCustom: 'var(--payment-palette-secondary)',
    mainRGB: 'var(--payment-palette-secondary-rgb)',
    darkCustom: 'var(--payment-palette-secondary-dark)',
    darkRGB: 'var(--payment-palette-secondary-dark-rgb)',
  },
  [PaletteColors.brandAccent]: {
    mainCustom: 'var(--payment-palette-accent)',
    mainRGB: 'var(--payment-palette-accent-rgb)',
  },
  [PaletteColors.customError]: {
    lightCustom: 'var(--payment-palette-custom-error-light)',
    mainOnLight: 'var(--payment-palette-custom-error-main-on-light)',
    mainCustom: 'var(--payment-palette-custom-error-main-on-light)',
    mainOnDark: 'var(--payment-palette-custom-error-main-on-dark)',
    darkCustom: 'var(--payment-palette-custom-error-dark)',
    lightRGB: 'var(--payment-palette-custom-error-light-rgb)',
    mainOnLightRGB: 'var(--payment-palette-custom-error-main-on-light-rgb)',
    mainOnDarkRGB: 'var(--payment-palette-custom-error-main-on-dark-rgb)',
    darkRGB: 'var(--payment-palette-custom-error-dark-rgb)',
  },
  [PaletteColors.customWarning]: {
    lightCustom: 'var(--payment-palette-custom-warning-light)',
    mainCustom: 'var(--payment-palette-custom-warning-main)',
    darkCustom: 'var(--payment-palette-custom-warning-dark)',
    lightRGB: 'var(--payment-palette-custom-warning-light-rgb)',
    mainRGB: 'var(--payment-palette-custom-warning-main-rgb)',
    darkRGB: 'var(--payment-palette-custom-warning-dark-rgb)',
  },
  [PaletteColors.customSuccess]: {
    lightCustom: 'var(--payment-palette-custom-success-light)',
    mainCustom: 'var(--payment-palette-custom-success-main)',
    darkCustom: 'var(--payment-palette-custom-success-dark)',
    lightRGB: 'var(--payment-palette-custom-success-light-rgb)',
    mainRGB: 'var(--payment-palette-custom-success-main-rgb)',
    darkRGB: 'var(--payment-palette-custom-success-dark-rgb)',
  },
  [PaletteColors.customInfo]: {
    lightCustom: 'var(--payment-palette-custom-info-light)',
    mainCustom: 'var(--payment-palette-custom-info-main)',
    darkCustom: 'var(--payment-palette-custom-info-dark)',
    lightRGB: 'var(--payment-palette-custom-info-light-rgb)',
    mainRGB: 'var(--payment-palette-custom-info-main-rgb)',
    darkRGB: 'var(--payment-palette-custom-info-dark-rgb)',
  },
  neutral: {
    0: 'var(--payment-palette-neutral-0)',
    50: 'var(--payment-palette-neutral-50)',
    100: 'var(--payment-palette-neutral-100)',
    200: 'var(--payment-palette-neutral-200)',
    400: 'var(--payment-palette-neutral-400)',
    600: 'var(--payment-palette-neutral-600)',
    700: 'var(--payment-palette-neutral-700)',
    800: 'var(--payment-palette-neutral-800)',
    900: 'var(--payment-palette-neutral-900)',
    RGB0: 'var(--payment-palette-neutral-0-rgb)',
    RGB50: 'var(--payment-palette-neutral-50-rgb)',
    RGB100: 'var(--payment-palette-neutral-100-rgb)',
    RGB200: 'var(--payment-palette-neutral-200-rgb)',
    RGB400: 'var(--payment-palette-neutral-400-rgb)',
    RGB600: 'var(--payment-palette-neutral-600-rgb)',
    RGB700: 'var(--payment-palette-neutral-700-rgb)',
    RGB800: 'var(--payment-palette-neutral-800-rgb)',
    RGB900: 'var(--payment-palette-neutral-900-rgb)',
  },
};
