import loadable from '@loadable/component';

export const MuiAccordion = loadable(
  () =>
    import(/* webpackChunkName: "MuiAccordion" */ '@mui/material/Accordion'),
);

export const MuiAccordionSummary = loadable(
  () =>
    import(
      /* webpackChunkName: "MuiAccordionSummary" */ '@mui/material/AccordionSummary'
    ),
);

export const MuiAccordionDetails = loadable(
  () =>
    import(
      /* webpackChunkName: "MuiAccordionDetails" */ '@mui/material/AccordionDetails'
    ),
);

export const MuiAlert = loadable(
  () => import(/* webpackChunkName: "MuiAlert" */ '@mui/material/Alert'),
);

export const MuiAvatar = loadable(
  () => import(/* webpackChunkName: "MuiAvatar" */ '@mui/material/Avatar'),
);

export const MuiBadge = loadable(
  () => import(/* webpackChunkName: "MuiBadge" */ '@mui/material/Badge'),
);

export const MuiButton = loadable(
  () => import(/* webpackChunkName: "MuiButton" */ '@mui/material/Button'),
);

export const MuiCheckbox = loadable(
  () => import(/* webpackChunkName: "MuiCheckbox" */ '@mui/material/Checkbox'),
);

export const MuiCircularProgress = loadable(
  () =>
    import(
      /* webpackChunkName: "MuiCircularProgress" */ '@mui/material/CircularProgress'
    ),
);

export const MuiClickAwayListener = loadable(
  () =>
    import(
      /* webpackChunkName: "MuiClickAwayListener" */ '@mui/material/ClickAwayListener'
    ),
);

export const MuiFloatingButton = loadable(
  () => import(/* webpackChunkName: "MuiFloatingButton" */ '@mui/material/Fab'),
);

export const MuiIconButton = loadable(
  () =>
    import(/* webpackChunkName: "MuiIconButton" */ '@mui/material/IconButton'),
);

export const MuiFormControl = loadable(
  () =>
    import(
      /* webpackChunkName: "MuiFormControl" */ '@mui/material/FormControl'
    ),
);

export const MuiInputAdornment = loadable(
  () =>
    import(
      /* webpackChunkName: "MuiInputAdornment" */ '@mui/material/InputAdornment'
    ),
);

export const MuiInputLabel = loadable(
  () =>
    import(/* webpackChunkName: "MuiInputLabel" */ '@mui/material/InputLabel'),
);

export const MuiFormGroup = loadable(
  () =>
    import(/* webpackChunkName: "MuiFormGroup" */ '@mui/material/FormGroup'),
);

export const MuiFormLabel = loadable(
  () =>
    import(/* webpackChunkName: "MuiFormLabel" */ '@mui/material/FormLabel'),
);

export const MuiFormControlLabel = loadable(
  () =>
    import(
      /* webpackChunkName: "MuiFormControlLabel" */ '@mui/material/FormControlLabel'
    ),
);

export const MuiFormHelperText = loadable(
  () =>
    import(
      /* webpackChunkName: "MuiFormHelperText" */ '@mui/material/FormHelperText'
    ),
);

export const MuiLink = loadable(
  () => import(/* webpackChunkName: "MuiLink" */ '@mui/material/Link'),
);

export const MuiListItemIcon = loadable(
  () =>
    import(
      /* webpackChunkName: "MuiListItemIcon" */ '@mui/material/ListItemIcon'
    ),
);

export const MuiMenuItem = loadable(
  () => import(/* webpackChunkName: "MuiMenuItem" */ '@mui/material/MenuItem'),
);

export const MuiPaper = loadable(
  () => import(/* webpackChunkName: "MuiPaper" */ '@mui/material/Paper'),
);

export const MuiRadio = loadable(
  () => import(/* webpackChunkName: "MuiRadio" */ '@mui/material/Radio'),
);

export const MuiSelect = loadable(
  () => import(/* webpackChunkName: "MuiSelect" */ '@mui/material/Select'),
);

export const MuiStepper = loadable(
  () => import(/* webpackChunkName: "MuiStepper" */ '@mui/material/Stepper'),
);

export const MuiStep = loadable(
  () => import(/* webpackChunkName: "MuiStep" */ '@mui/material/Step'),
);

export const MuiStepLabel = loadable(
  () =>
    import(/* webpackChunkName: "MuiStepLabel" */ '@mui/material/StepLabel'),
);

export const MuiTab = loadable(
  () => import(/* webpackChunkName: "MuiTab" */ '@mui/material/Tab'),
);

export const MuiTabs = loadable(
  () => import(/* webpackChunkName: "MuiTabs" */ '@mui/material/Tabs'),
);

export const MuiTextField = loadable(
  () =>
    import(/* webpackChunkName: "MuiTextField" */ '@mui/material/TextField'),
);

export const MuiTooltip = loadable(
  () => import(/* webpackChunkName: "MuiTooltip" */ '@mui/material/Tooltip'),
);

export const MuiTypography = loadable(
  () =>
    import(/* webpackChunkName: "MuiTypography" */ '@mui/material/Typography'),
);

export const MuiRadioGroup = loadable(
  () =>
    import(/* webpackChunkName: "MuiRadioGroup" */ '@mui/material/RadioGroup'),
);

export const MuiDatePicker = loadable(
  () => import(/* webpackChunkName: "MuiDatePicker" */ '@mui/x-date-pickers'),
  {
    resolveComponent: (datePickerModule) => datePickerModule.DatePicker,
  },
);

export const MuiDatePickerLocalizationProvider = loadable(
  () =>
    import(
      /* webpackChunkName: "MuiDatePickerLocalizationProvider" */ '@mui/x-date-pickers/LocalizationProvider'
    ),
  {
    resolveComponent: (datePickerLocalizationProvider) => {
      return datePickerLocalizationProvider.LocalizationProvider;
    },
  },
);

export const MuiBox = loadable(
  () => import(/* webpackChunkName: "MuiBox" */ '@mui/material/Box'),
);

export const MuiGrid = loadable(
  () => import(/* webpackChunkName: "MuiGrid" */ '@mui/material/Grid'),
);

export const MuiStack = loadable(
  () => import(/* webpackChunkName: "MuiStack" */ '@mui/material/Stack'),
);

export const MuiDivider = loadable(
  () => import(/* webpackChunkName: "MuiDivider" */ '@mui/material/Divider'),
);

export const MuiCollapse = loadable(
  () => import(/* webpackChunkName: "MuiCollapse" */ '@mui/material/Collapse'),
);

export const MuiFade = loadable(
  () => import(/* webpackChunkName: "MuiCollapse" */ '@mui/material/Fade'),
);

export const MuiSvgIcon = loadable(
  () => import(/* webpackChunkName: "MuiSvgIcon" */ '@mui/material/SvgIcon'),
);

export const MuiPopper = loadable(
  () => import(/* webpackChunkName: "MuiPopper" */ '@mui/material/Popper'),
);

export const MuiDrawer = loadable(
  () => import(/* webpackChunkName: "MuiDrawer" */ '@mui/material/Drawer'),
);
