import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { MuiTextField } from '../../../../../modules/material';
import FieldStateAdornment from '../FieldStateAdornment';

export interface TextInputProps extends Omit<MuiTextFieldProps, 'variant'> {
  completed?: boolean;
}

export default function TextInput(props: TextInputProps) {
  const {
    children,
    completed,
    error,
    InputProps,
    InputLabelProps,
    minRows = 2,
    fullWidth = true,
    ...textFieldProps
  } = props;

  return (
    <MuiTextField
      error={error}
      variant="outlined"
      InputProps={{
        // Need to be undefined to prevent showing empty space on focus
        label: undefined,
        endAdornment: (
          <FieldStateAdornment error={error} completed={completed} />
        ),
        ...InputProps,
      }}
      InputLabelProps={{
        variant: 'filled',
        ...InputLabelProps,
      }}
      minRows={minRows}
      fullWidth={fullWidth}
      {...textFieldProps}
    >
      {children}
    </MuiTextField>
  );
}
