import { PropsWithChildren } from 'react';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import { renderCSSString } from '../../../../helpers';
import { PaymentBrandColors, useBrandColors } from '../../hooks/useBrandColors';
import '../styles/styles.scss';

export type GlobalStyleProps = {
  brand: PaymentBrandColors;
};

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  :root {
    --payment-palette-primary: ${({ brand }) => brand.primary};
    --payment-palette-primary-rgb: ${({ brand }) => brand.primaryRGB};
    --payment-palette-primary-light: ${({ brand }) => brand.primaryLight};
    --payment-palette-primary-light-rgb: ${({ brand }) =>
      brand.primaryLightRGB};
    
    --payment-palette-secondary: ${({ brand }) => brand.secondary};
    --payment-palette-secondary-rgb: ${({ brand }) => brand.secondaryRGB};
    --payment-palette-secondary-dark: ${({ brand }) => brand.secondaryDark};
    --payment-palette-secondary-dark-rgb: ${({ brand }) =>
      brand.secondaryDarkRGB};
    
    --payment-palette-accent: ${({ brand }) => brand.accent};
    --payment-palette-accent-rgb: ${({ brand }) => brand.accentRGB};
  }
`;

export default function ThemeProvider({ children }: PropsWithChildren) {
  const brandColors = useBrandColors();

  return (
    <>
      <GlobalStyle brand={brandColors} />
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      {/*
        The usage of renderCSSString is specific to providing styling support
        for the "BOOK NOW" button within the multi-level navigation component.
      */}
      {renderCSSString(`
        .c-cta--primary,
        .c-cta--primary:hover {
          background-color: ${brandColors.primary};
        }

        .c-cta--secondary,
        .c-cta--secondary:hover {
          background-color: ${brandColors.secondary};
        }
               
        .c-cta--accent,
        .c-cta--accent:hover {
          background-color: ${brandColors.accent};
          color: rgba(0, 0, 0, 0.8);
        }`)}
    </>
  );
}
