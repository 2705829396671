import { styled } from '@mui/material/styles';
import { MuiTypography } from '../../../../../modules/material';
import { TypographyProps } from './index';

const styledTypographyOptions = {
  shouldForwardProp: (prop: string) =>
    !['neutralColorIndex', 'paletteColorName'].includes(prop),
};

export const StyledTypography = styled(
  MuiTypography,
  styledTypographyOptions,
)<TypographyProps>((props) => {
  const { opacity, neutralColorIndex, paletteColorName, theme } = props;

  return {
    ...(opacity && {
      opacity,
    }),
    ...(neutralColorIndex && {
      color: theme.palette.neutral[neutralColorIndex],
    }),
    ...(paletteColorName && {
      color: theme.palette[paletteColorName].mainCustom,
    }),
  };
});
